import { useRouter } from 'next/router';
import React, { useEffect } from 'react';
import Cookies from 'react-cookies';
import LoadingPlaceholder from '../../components/_generic/loading-placeholder/LoadingPlaceholder';
import COOKIES from '../../config/cookies/cookies';
import { SITE_LIVINGSOCIAL_IE } from '../../config/setup/setup';
import { updatePersistentProperty } from '../../helpers/analytics';
import {
  isLivingSocialBrand,
  isWowcherBrand,
  isWowcherIE,
} from '../../helpers/pages';
import { getServerSideEssentials } from '../../redux/actions/ssr';
import { wrapper } from '../../redux/store/store';

const Redirects = () => {
  const router = useRouter();

  // Redirect to Local URL
  const locationCookie = Cookies.load(COOKIES.location);
  const defaultLocation =
    process.env.NEXT_PUBLIC_SITE === SITE_LIVINGSOCIAL_IE
      ? 'dublin-south'
      : isWowcherIE()
      ? 'dublin'
      : 'london';

  const homePageRedirectSlug = locationCookie?.shortName
    ? locationCookie?.shortName
    : defaultLocation;
  const localUrl = `/deals/${homePageRedirectSlug}`;
  const nationalUrl = `/deals/shop`;
  let url = isLivingSocialBrand() ? localUrl : nationalUrl;

  if (isWowcherBrand()) {
    url = locationCookie?.shortName === 'nottingham' ? localUrl : nationalUrl;
  }

  // PRD-6206 for mothers day 2025
  url = localUrl;

  updatePersistentProperty('homepageRedirectedTo', url);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      router.replace(url);
    }
  }, []);

  return <LoadingPlaceholder />;
};

export const getServerSideProps = wrapper.getServerSideProps(
  async ({ req, store }) => {
    await store.dispatch(getServerSideEssentials(req.url)).catch((error) => {
      console.log(`getServerSideEssentials error: ${error}`);
    });
  },
);

export default Redirects;
